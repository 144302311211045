// extracted by mini-css-extract-plugin
export var cProfesBox = "index-module--cProfesBox--HS0e+";
export var cProfesBox2 = "index-module--cProfesBox2--U3ZNa";
export var cProfesBox3 = "index-module--cProfesBox3--wO9hf";
export var cSecDesc = "index-module--cSecDesc--rKqtk";
export var sAboutUs = "index-module--sAboutUs--M2j1n";
export var sAboutUsSec = "index-module--sAboutUsSec--1Oab+";
export var sArticleSec = "index-module--sArticleSec--Svdvi";
export var sImgWrap = "index-module--sImgWrap--XeXuG";
export var sIntro = "index-module--sIntro--snxIl";
export var sIntroDesc = "index-module--sIntroDesc--QjfSs";
export var sIntroSection = "index-module--sIntroSection--nu89z";
export var sParagraph = "index-module--sParagraph--tFAUM";