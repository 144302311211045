module.exports = {
  seo: {
    title: "מי אנחנו - Jenko",
    description: "",
  },
  intro: {
    title: "מי אנחנו?",
    desc: "חברת ג'נקו עוסקת בפיתוח ותכנון מוצרים בהתאמה לצרכים המשתנים של לקוחותיה\u00A0המגוונים",
  },
  profes:
    "אנו מתמחים במתן שירותי עיצוב תעשייתי, פיתוח הנדסי, תכנון והנדסת מוצר, אלקטרוניקה, אלקטרומכניקה, מחקר ופיתוח, זיווד, העברה מפיתוח לייצור, שירותי ניהול\u00A0טכנולוגי-CTO במגזר הפרטי ג'נקו מלווה את היזם לאורך כל הדרך משלב הרעיון ועד ייצור ושיווק של המוצר.",
  develop:
    "אנו מפתחים מוצרים מתחום הרפואה, פלסטיקה, אלקטרוניקה, ג'אדגטים\u00A0ועוד.",
  belive:
    "אנחנו מאמינים בעבודה לפי אבני דרך ״עקב לצד אגודל״ ולכן עובדים לפי שלבים\u00A0בטוחים:",
  features: [
    "אפיון ובדיקת היתכנות",
    "תכנון הנדסי",
    "הפקת אב טיפוס ",
    "חיפוש וכתיבת פטנט",
    "גיוס משקיעים",
    "ייצור המוני",
    "שיווק",
  ],
  outro:
    "Jenko 3D Engineering מהווה פתרון אולטימיטיבי לכל  יזם או חברה המחפשים לפתח מוצר, לא משנה באיזה שלב נמצאים, מה הצרכים ומה ההשלמות שיש לבצע. אנו מעוניינים לתת one-stop shop, מעטפת כוללת שדרכה הלקוח מקבל\u00A0מענה\u00A0להכל.",
  dir: "rtl",
};
